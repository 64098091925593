class searchFrom {
  validateSort = (rule, value, callback) => {
    if (value) {
      if (value <= 0) {
        callback([new Error('数值应为正整数')]);
      } else if (value > 99999) {
        callback([new Error('数值需小于100000')]);
      } else {
        callback();
      }
    } else {
      callback([new Error('请输入分类顺序')]);
    }
  }

  constructor(type) {
    if (type === 'form') {
      this.categoryName = ''
      this.sort = ''
      this.categoryPic = ''
      this.categoryPicUrl = ''
      this.top = 'Y'
      this.mapIcoIcon = ''
      this.mapMarkIcon = ''
    } else if (type === 'rule') {
      this.categoryName = [
        {
          required: true,
          message: '请输入分类名称',
          trigger: 'change',
        },
      ]
      this.sort = [
        {
          required: true,
          validator: this.validateSort,
          trigger: ['change', 'blur'],
        },
      ]
      this.top = [
        {
          required: true,
          message: '请选择',
          trigger: 'change',
        },
      ]

      const CHOSE_IMAGE = [{
          required: true,
          message: '请选择图片',
          trigger: 'change',
        }]

      this.mapIcoIcon = CHOSE_IMAGE
      this.mapMarkIcon = CHOSE_IMAGE
      this.categoryPic = CHOSE_IMAGE
    }
  }
}
export default searchFrom
